import { Radio, RadioChangeEvent } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PlansRadioGroup from "../../POS/steps/PlansRadioGroup";
import { updateShopStockCartDetailsAction } from "../../../../store/wareHouse/actions";
import { useParams } from "react-router-dom";
import { getActivePlan } from "../../../../utils/converter";
import { getDefaultCurrencyCode } from "../../../../utils/CurrencyHelper";

const SubscriptionInfo: React.FC<{
  planType: any;
  active: any;
  setActiveInstalment: any;
  activeInstalment: any;
}> = ({ active, setActiveInstalment, activeInstalment }) => {
  const { auth, wareHouse ,company} = useSelector((state: any) => state);
  const { cartId } = useParams();
  const dispatch = useDispatch();

  const subsPlans = wareHouse?.createdCart?.data?.list?.map(
    (plan: any, _index: number) => plan?.priceProduct?.subscriptionPlan
  );
  const onInstalmentChange = async (e: RadioChangeEvent) => {
    setActiveInstalment(e.target.value);
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      instalmentPlan: e.target.value,
    })(dispatch);
  };

  const ActivePlan = getActivePlan(
    subsPlans,
    wareHouse?.createdCart?.data?.subscriptionPlan
  );

  return (
    <div>
      <p className="py-3">Subscription duration</p>
      {/* <hr className="mb-3 mt-1" /> */}
      <PlansRadioGroup active={active} disabled />
      {/* <p className="py-2 md:py-0">Here is the payment plan</p> */}

      {subsPlans &&
        subsPlans[0]
          ?.filter(
            (plan: any) =>
              plan?.duration === wareHouse?.createdCart?.data?.subscriptionPlan
          )
          ?.map((plan: any, index: number) => (
            <div className="flex justify-start border-t pt-2">
              <Radio.Group
                onChange={onInstalmentChange}
                value={activeInstalment}
                className="flex flex-wrap pb-2"
                disabled
              >
                {/* <Radio
                  value={"daily"}
                  className={`w-full h-9 border pl-4 mb-2 rounded-tl-md rounded-br-md flex items-center ${
                    activeInstalment === "daily" && "border-yellow-400"
                  }`}
                >
                  <div className="w-full xbg-red-400 flex justify-between">
                    <p>Daily </p> */}
                    {/* <span className="text-right flex-1">{`${plan?.initialPayment?.toLocaleString()} ${getDefaultCurrencyCode(company)}`}</span> */}
                  {/* </div>
                </Radio> */}

                {/* <Radio
                  value={"weekly"}
                  className={`w-full h-9 border pl-4 mb-2 rounded-tl-md rounded-br-md flex items-center ${
                    activeInstalment === "weekly" && "border-yellow-400"
                  }`}
                >
                  <div className="w-full xbg-red-400 flex justify-between">
                    <p>Weekly </p> */}
                    {/* <span className="text-right">{`${plan?.weeklyInstalment?.toLocaleString()} ${getDefaultCurrencyCode(company)}`}</span> */}
                  {/* </div>
                </Radio> */}

                <Radio
                  value={"monthly"}
                  className={`w-full h-9 border pl-4 mb-2 rounded-tl-md rounded-br-md flex items-center ${
                    activeInstalment === "monthly" && "border-yellow-400"
                  }`}
                >
                  <div className="w-full xbg-red-400 flex justify-between">
                    <p>Monthly </p>
                    {/* <span className="text-right">{`${plan?.monthlyInstalment?.toLocaleString()} ${getDefaultCurrencyCode(company)}`}</span> */}
                  </div>
                </Radio>

                {/* instalment payments details */}
                <div className="w-full mt-5 flex justify-between">
                  {wareHouse?.createdCart?.data?.instalmentPlan ===
                    "weekly" && (
                    <>
                      <span className="font-bold text-neutral-800">
                        {/* {!disableCheckout && (
                                  <DoneAllIcon className="text-blue-500 mr-1" />
                                )} */}
                        Weekly Payment Amount:
                      </span>
                      <span
                        className={`text-right font-bold text-slate-800`}
                      >{`${
                        ActivePlan &&
                        ActivePlan.at(0)?.weeklyInstalment?.toLocaleString()
                      } ${getDefaultCurrencyCode(company)}`}</span>
                    </>
                  )}
                  {wareHouse?.createdCart?.data?.instalmentPlan === "daily" && (
                    <>
                      <span className="font-bold text-neutral-800">
                        {/* {!disableCheckout && (
                                  <DoneAllIcon className="text-blue-500 mr-1" />
                                )} */}
                        Daily Payment Amount:
                      </span>
                      <span
                        className={`text-right font-bold text-slate-800`}
                      >{`${
                        ActivePlan &&
                        ActivePlan.at(0)?.dailyInstalment?.toLocaleString()
                      } ${getDefaultCurrencyCode(company)}`}</span>
                    </>
                  )}
                  {wareHouse?.createdCart?.data?.instalmentPlan ===
                    "monthly" && (
                    <>
                      <span className="font-bold text-neutral-800">
                        {/* {!disableCheckout && (
                                  <DoneAllIcon className="text-blue-500 mr-1" />
                                )} */}
                        Monthly Payment Amount:
                      </span>
                      <span
                        className={`text-right font-bold text-slate-800`}
                      >{`${
                        (ActivePlan &&
                          ActivePlan.at(
                            0
                          )?.monthlyInstalment?.toLocaleString()) ||
                        0
                      } ${getDefaultCurrencyCode(company)}`}</span>
                    </>
                  )}
                </div>

                <div className="w-full mt-5 flex justify-between">
                  <span className="font-bold text-neutral-800">
                    {/* {!disableCheckout && (
                                  <DoneAllIcon className="text-blue-500 mr-1" />
                                )} */}
                    Initial Payment Amount:
                  </span>
                  {/* <span
                                    className={`text-right font-bold text-slate-800`}
                                  >{`${getActivePlan(
                                    subsPlans,
                                    wareHouse?.createdCart?.data
                                      ?.subscriptionPlan
                                  )
                                    .at(0)
                                    ?.initialPayment?.toLocaleString()} ${getDefaultCurrencyCode(company)}`}</span> */}
                  <span className={`text-right font-bold text-slate-800`}>{`${
                    ActivePlan &&
                    ActivePlan.at(0)?.initialPayment?.toLocaleString()
                  } ${getDefaultCurrencyCode(company)}`}</span>
                </div>
              </Radio.Group>
            </div>
          ))}
    </div>
  );
};

export default SubscriptionInfo;
