import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { notification, Result } from "antd";
import { getShopStockCartAction, updateShopStockCartDetailsAction } from "../../../store/wareHouse/actions";
import { createAccountAction } from "../../../store/pos/actions";
import { handleNextStepAction } from "../../device_financing/Subscription/redux/layout/actions";
import { CiEdit } from "react-icons/ci";
import { getDefaultCurrencyCode } from "../../../utils/CurrencyHelper";

const ApplicantsDetails = () => {
  const { wareHouse, auth, pos,company } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { cartId } = useParams();
  const navigate = useNavigate();

  const isSubmitted = wareHouse?.createdCart?.data?.isSubscriptionSubmitted;

  const userImage =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.photoCustomerHoldingID;

  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;
  const address =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address;

  const handlePrevious = async () => {
    await handleNextStepAction(6)(dispatch);
  };

  const handleSendRequest = async () => {
    const resPre = await updateShopStockCartDetailsAction(auth?.token, cartId, {
      isSubscriptionSubmitted: true,
      subscriptionStatus: "ACCOUNT_REQUEST",
      status: "requested",
      payment: [
        {
          mode: "MoMo_Pay",
          amount: wareHouse?.createdCart?.data?.selectedPlan?.initialPayment,
        },
      ],
    })(dispatch);
    if (resPre) {
      await getShopStockCartAction(auth?.token, cartId)(dispatch);
      await createAccountAction(auth?.token, cartId as string)(dispatch);
      navigate("/home");
      notification.success({ message: "Request Sent Successfully" });
    }
  };
      const phone = wareHouse?.createdCart?.data?.customer?.phone;
const maskedPhone = phone ? `${phone.slice(0, 5)}*********${phone.slice(-2)}` : "";

      const pNationalId = wareHouse?.createdCart?.data?.customer?.nationalId;
const maskedNationalId = pNationalId ? `${pNationalId.slice(0, 2)}*********${pNationalId.slice(-2)}` : "";

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="md:flex gap-5 w-full">
        <div className="border border-[#0302291A] w-full p-4 mt-5 rounded-md">
          <div className="flex justify-between">
            <h1 className="text-[#030229] text-base font-medium py-3 pb-6 text-center">
              Personal Details
            </h1>
            <div
              className="bg-[#E5E5E5] rounded-md h-[26px] w-[56px] flex justify-center items-center gap-2 cursor-pointer"
              //   onClick={showModal}
            >
              <CiEdit size={15} />
              <p className="text-[12px]">Edit</p>
            </div>
          </div>
          <div className="flex w-full gap-12 mt-6">
            <p className="text-[#03022980]  text-sm">Full Name</p>
            <p className="text-[#030229] text-sm">
              {wareHouse?.createdCart?.data?.customer?.name}
            </p>
          </div>
          <div className="flex w-full gap-5 mt-6">
            <p className="text-[#03022980]  text-sm">Personal Email</p>
            <p className="text-[#030229] text-sm">
              {wareHouse?.createdCart?.data?.customer?.email}
            </p>
          </div>
          <div className="flex w-full gap-5 mt-3">
            <p className="text-[#03022980]  text-sm">Phone Number</p>
            <p className="text-[#030229] text-sm">
              {maskedPhone}
            </p>
          </div>
          <div className="flex w-full gap-12 mt-3">
            <p className="text-[#03022980]  text-sm">National Id</p>
            <p className="text-[#030229] text-sm">
              {maskedNationalId}
            </p>
          </div>
          <div className="flex items-end justify-end w-full">
            <div className="text-[#605BFF] py-1 px-2 rounded-md text-base border border-[#605BFF] w-[13.5rem] mt-4">
              <p>View Other Contacts (2)</p>
            </div>
          </div>
        </div>
        <div className="border border-[#0302291A] p-4 mt-5 rounded-md w-full">
          <div className="flex justify-between">
            <h1 className="text-[#030229] text-base font-medium py-3 pb-6 text-center">
              Address Details
            </h1>
            <div
              className="bg-[#E5E5E5] rounded-md h-[26px] w-[56px] flex justify-center items-center gap-2 cursor-pointer"
              //   onClick={showReceiverModal}
            >
              <CiEdit size={15} />
              <p className="text-[12px]">Edit</p>
            </div>
          </div>
          <div className="flex w-full gap-10 mt-3">
            <p className="text-[#03022980]  text-sm"> Country</p>
            <p className="text-[#030229] text-sm">{address?.country}</p>
          </div>
          <div className="flex w-full gap-10 mt-3">
            <p className="text-[#03022980]  text-sm"> province</p>
            <p className="text-[#030229] text-sm">{address?.province}</p>
          </div>
          <div className="flex w-full gap-10 mt-3">
            <p className="text-[#03022980]  text-sm"> District</p>
            <p className="text-[#030229] text-sm">{address?.district}</p>
          </div>
          <div className="flex w-full gap-10 mt-3">
            <p className="text-[#03022980]  text-sm">Sector</p>
            <p className="text-[#030229] text-sm">{address?.sector}</p>
          </div>
          <div className="flex w-full gap-10 mt-3">
            <p className="text-[#03022980]  text-sm">Cell</p>
            <p className="text-[#030229] text-sm">{address?.cell}</p>
          </div>
          <div className="flex w-full gap-10 mt-3">
            <p className="text-[#03022980]  text-sm">Village</p>
            <p className="text-[#030229] text-sm">{address?.village}</p>
          </div>
        </div>
      </div>
      <div className="border border-[#0302291A] p-4 mt-5 rounded-lg">
        <h1 className="text-[#030229] text-lg font-medium py-3 pb-6 text-center">
          Documents
        </h1>
        <div className=" w-full">
          <div className=" w-full">
            <p className="text-[#030229]  text-sm">Passport image</p>
            <div className="pb-10 pt-5 w-full flex items-center justify-center rounded-md">
              {/* <img
                src={userImage}
                alt=""
                className="h-32 object-contain w-full mt-2 rounded-md"
              /> */}
              {userImage && (
                <Result
                  status="success"
                />
              )}
            </div>
          </div>
          <div className="w-full">
            <p className="text-[#030229]  text-sm">National Id</p>
            <div className="pb-10  pt-5 w-full flex items-center justify-center rounded-md">
              {/* <img
                src={nationalId}
                alt=""
                className="h-32 object-contain w-full mt-2 rounded-md"
              /> */}
               {nationalId && (
                <Result
                  status="success"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="border border-[#0302291A] p-4 mt-5 rounded-md w-full">
        <div className="flex justify-between">
          <h1 className="text-[#030229] text-base font-medium py-3 pb-6 text-center">
            Plan Details
          </h1>
          <div
            className="bg-[#E5E5E5] rounded-md h-[26px] w-[56px] flex justify-center items-center gap-2 cursor-pointer"
            //   onClick={showReceiverModal}
          >
            <CiEdit size={15} />
            <p className="text-[12px]">Edit</p>
          </div>
        </div>
        <div className="flex w-full gap-10 mt-3">
          <p className="text-[#03022980]  text-sm"> Device</p>
          <p className="text-[#030229] text-sm">
            {
              wareHouse?.createdCart?.data?.selectedPlan?.pricelistItem?.product
                ?.model
            }
          </p>
        </div>
        <div className="flex w-full gap-10 mt-5 flex-wrap">
          {wareHouse?.createdCart?.data?.selectedPlan?.pricelistItem?.specification?.map(
            (el: any, index: number) => {
              return (
                <div key={index}>
                  <p className="text-[#03022980] text-sm">{el[0]}</p>
                  <p className="text-[#030229] text-sm">{el[1]}</p>
                </div>
              );
            }
          )}
        </div>
        <div className="flex w-full gap-10 mt-3">
          <p className="text-[#03022980]  text-sm">Installment Amount</p>
          <p className="text-[#030229] text-sm">
            ${getDefaultCurrencyCode(company)}{" "}
            {wareHouse?.createdCart?.data?.selectedPlan?.monthlyInstalment?.toLocaleString()}
          </p>
        </div>
        <div className="flex w-full gap-10 mt-3">
          <p className="text-[#03022980]  text-sm">Plan Period</p>
          <p className="text-[#030229] text-sm ">
            {wareHouse?.createdCart?.data?.selectedPlan?.duration} Months
          </p>
        </div>
        <div className="flex w-full gap-10 mt-3">
          <p className="text-[#03022980]  text-sm">Installment Plan</p>
          <p className="text-[#030229] text-sm capitalize">
            {wareHouse?.createdCart?.data?.instalmentPlan}
          </p>
        </div>
        <div className="flex w-full gap-10 mt-3">
          <p className="text-[#03022980]  text-sm">Initial Payment</p>
          <p className="text-[#030229] text-sm">
            ${getDefaultCurrencyCode(company)}{" "}
            {wareHouse?.createdCart?.data?.selectedPlan?.initialPayment?.toLocaleString()}
          </p>
        </div>
      </div>
      <div
        className={`flex ${
          isSubmitted && wareHouse?.createdCart?.data?.status !== "requested"
            ? "justify-center"
            : "justify-between"
        } mt-8`}
      >
        {!isSubmitted ? (
          <LoadingButton
            variant="outlined"
            onClick={handlePrevious}
            className="w-[150px] text-lg uppercase"
          >
            Previous
          </LoadingButton>
        ) : (
          wareHouse?.createdCart?.data?.status === "requested" && <div></div>
        )}
        {["pending", "requested"]?.includes(
          wareHouse?.createdCart?.data?.status
        ) ? (
          <LoadingButton
            variant="contained"
            sx={{ background: "#605BFF" }}
            loading={pos?.isFetching || wareHouse?.isUpdateFetching}
            onClick={handleSendRequest}
            className="w-[150px] text-lg uppercase "
          >
            {wareHouse?.createdCart?.data?.status === "requested"
              ? "Waiting for approval"
              : " Send request"}
          </LoadingButton>
        ) : (
          !isSubmitted && <div></div>
        )}
      </div>
    </div>
  );
};

export default ApplicantsDetails;
