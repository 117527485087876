import * as React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { getActiveShop } from "../../utils/converter";
import { useLocation, useNavigate } from "react-router-dom";
import SearchButton from "../SearchButton";
import * as aiIcons from "react-icons/ai";
import * as bsIcons from "react-icons/bs";
import { Input, Tooltip } from "antd";
import { Dropdown } from "antd";
import UserProfile from "./userProfile";
import GetNotification from "./Notification";
import { ReactComponent as ShippingBag } from "../../assets/images/shopping-bag.svg";
const Component: React.FC<{
  nav: any;
  title: any;
  handleFullScreenClick: any;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}> = ({ nav, title, handleFullScreenClick, isOpen, setIsOpen }) => {
  const { layout, pos } = useSelector((state: any) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const route = location.pathname;
  const { auth, shop } = useSelector((state: any) => state);
  const shopInfo = getActiveShop(auth?.user?.shop?.assigned)[0] || shop?.myShop;
  const [arrow, setArrow] = React.useState("Show");
  const smallScreen = useMediaQuery("(max-width:1023px)");
  const verySmallScreen = useMediaQuery("(max-width:639px)");
  const extraSmallScreen = useMediaQuery("(max-width:330px)");
  const [searchBtn, setSearchBtn] = React.useState(false);
  const [profileComponent, setProfileComponent] = React.useState(false);
  const showSearchBtn = () => {
    setSearchBtn(!searchBtn);
  };
  const showProfileComponent = () => {
    if (verySmallScreen) {
      setProfileComponent(!profileComponent);
    } else {
      setProfileComponent(false);
    }
  };
  const { Search } = Input;
  const items = [
    {
      key: "1",
      label: (
        <div className="flex gap-3">
          <GetNotification /> <p className="pt-3">Notification</p>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="flex gap-5 ml-3">
          <bsIcons.BsFillQuestionCircleFill
            size={23}
            className="mt-4 xl:mt-3 cursor-pointer"
            color="black"
            onClick={() => navigate("/knowledge")}
          />
          <p className="pt-3">Knowledge</p>
        </div>
      ),
    },
    // {
    //   key: "3",
    //   label: <UserProfile openSide={isOpen}/>,
    // },
  ];
  const mergedArrow = React.useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  return (
    <div className="fixed top-0 w-full z-10">
      <div
        className={`relative w-full  md:px-4 sm-h-13 py-4  gap-1 bg-white text-black ${
          layout.isSideNavOpen && !smallScreen ? "pl-[229px]" : "pl-[35px] md:pl-[70px]"
        } ${verySmallScreen && "py-2"} ${
          !verySmallScreen && smallScreen && "py-1 "
        } }
        `}
      >
        <div
          className={`flex justify-between items-center space-x-0 z-50 flex-1 pr-3 md:pr-10 ${
            extraSmallScreen && " pr-0"
          }`}
        >
          {useMediaQuery("(max-width:500px)") && searchBtn ? (
            ""
          ) : (
            <>
              <p
                className={`text-gray-500 capitalize  text-sm md:text-base ${(layout.isSideNavOpen && !smallScreen ) ?"pl-52":"pl-6"} ${
                  smallScreen && "pr-6 pl-2"
                } ${extraSmallScreen && "pl-0"} ${
                  smallScreen &&
                  layout.isSideNavOpen &&
                  !verySmallScreen &&
                  "pl-40"
                }`}
              >
                {route?.split("/")[1]}
              </p>

              <div className="lg:hidden block"> <Tooltip
                placement="bottom"
                title=<h1>Pending Sales</h1>
                arrow={mergedArrow}
              >
                <div
                  className="relative cursor-pointer"
                  onClick={() => navigate("/sale")}
                >
                  {/* <Badge count={props?.data?.carts?.data? props?.data?.carts?.data?.length:0}>
        <Avatar icon={<ShippingBag />} />
      </Badge> */}
                  <ShippingBag className=" mt-1d xl:mt-1 w-7 xl:w-9 h-7 xl:h-9" />
                  <div className="absolute h-4 w-4 bg-[#F1595C] rounded-full top-4 xl:top-2 right-0 z-2s00 flex items-center justify-center">
                    {" "}
                    <span className="text-white text-bases">
                      {pos?.carts?.data?.length}
                    </span>
                  </div>
                </div>
              </Tooltip></div>
            </>
          )}

          {smallScreen ? (
            !searchBtn &&
            (extraSmallScreen ? (
              <aiIcons.AiOutlineSearch size={18} onClick={showSearchBtn} className="hidden sm:block"/>
            ) : (
              <aiIcons.AiOutlineSearch size={24} onClick={showSearchBtn} className="hidden sm:block"/>
            ))
          ) : (
            <>
              <div className="flex gap-10">
                <SearchButton />
                <div className="hidden md:block">
                  {" "}
                  <Tooltip
                    placement="bottom"
                    title=<h1>Pending Sales</h1>
                    arrow={mergedArrow}
                  >
                    <div
                      className="relative cursor-pointer"
                      onClick={() => navigate("/sale")}
                    >
                      <ShippingBag className=" mt-3 xl:mt-1 w-7 xl:w-9 h-7 xl:h-9" />
                      <div className="absolute h-4 w-4 bg-[#F1595C] rounded-full top-4 xl:top-2 right-0 z-2s00 flex items-center justify-center">
                        {" "}
                        <span className="text-white text-bases">
                          {pos?.carts?.data?.length}
                        </span>
                      </div>
                    </div>
                  </Tooltip>
                </div>
                <bsIcons.BsFillQuestionCircleFill
                  size={23}
                  className="mt-4 xl:mt-3 cursor-pointer"
                  color="black"
                  onClick={() => navigate("/knowledge")}
                />
                <hr className="h-8 w-[1px] mt-3 xl:mt-2 bg-gray-200" />
                <div className="mt-1 xl:mt-0">
                  <GetNotification />
                </div>
                <hr className="h-8 w-[1px] mt-3 bg-gray-200" />
                {(!searchBtn && !smallScreen)&& <><UserProfile openSide={isOpen}/></>} 
              </div>
            </>
          )}
          {smallScreen && !searchBtn && !profileComponent ? (
            <>
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottom"
              >
                <bsIcons.BsThreeDotsVertical />
              </Dropdown>
            </>
          ) : (
            !searchBtn && (
              <>
                {verySmallScreen && (
                  <aiIcons.AiOutlineClose
                    onClick={showProfileComponent}
                    className="float-right"
                  />
                )}{" "}
              </>
            )
          )}
           {(!searchBtn && smallScreen)&& <><UserProfile openSide={isOpen}/></>}
          {searchBtn && smallScreen && (
            <>
              <SearchButton />
              <div className=" pl-4 ">
                <aiIcons.AiOutlineClose onClick={showSearchBtn} size={20} />
              </div>
            </>
          )}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Component;
