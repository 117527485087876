import React, { useState } from "react";
import { Badge, Carousel, Skeleton } from "antd";
import { ReactComponent as OkIcon } from "../../../assets/icons/Group 134142.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getShopStockCartAction,
  removeCartItemAction,
} from "../../../store/wareHouse/actions";
import { useParams } from "react-router-dom";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { getDefaultCurrencyCode } from "../../../utils/CurrencyHelper";
const CartItems = (props: any) => {
  const { auth, wareHouse ,company} = useSelector((state: any) => state);
  const { cartId } = useParams();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<any>(null);
  const handleClickDelete = async (data: any) => {
    setVisible(true);
    setData(data);
  };
  const handleDeleteModalCancels = () => {
    setVisible(false);
  };
  const deleteProduct = async () => {
    if (auth?.token) {
      await removeCartItemAction(auth?.token, data?._id)(dispatch);
      await getShopStockCartAction(auth?.token, cartId)(dispatch);
      handleDeleteModalCancels();
    }
  };

  const priceLabel =
    wareHouse?.createdCart?.data?.isSubscription === "subscription"
      ? "Upfront"
      : "Price";

  return (
    <>
      {wareHouse?.isFetching ? (
        <Skeleton />
      ) : (
        <div className=" w-full sm:w-[95%] mt-2">
          {props?.data?.map((d: any) => {
            const extendedWarranty =
              d?.shopStockItem?.requestedItem?.product?.extendedWarranty;
            const itemPrice = d?.payment?.amount;

            return (
              <Badge.Ribbon
                text=<span className="text-[#3AC722] text-sm">{`${getDefaultCurrencyCode(company)} ${(
                  itemPrice +
                  (d?.payment?.amount * d?.payment?.extendedWarranty) / 100
                )?.toLocaleString()}`}</span>
                color="#E1F7DE"
                className="h-7 pt-1 w-36 text-center"
              >
                <Badge.Ribbon
                  text=<span className="text-[#605BFF] text-[10px]">
                    + Extended Warranty{extendedWarranty} %
                  </span>
                  color="#EDEDFE"
                  className="h-7 pt-1 mt-10 w-36 text-center"
                >
                  <div className="border border-[#03022940] rounded-lg p-3 mt-2">
                    <div className="lg:flex gap-5">
                    {!["PAYMENT_DONE","VAULT_UPLOADED","VAULT_ACTIVATED","COMPLETED"]?.includes(wareHouse?.createdCart?.data?.subscriptionStatus ) &&  <OkIcon
                        className="cursor-pointer"
                        onClick={() => handleClickDelete(d)}
                      />}
                      <div className="2xl:flex gap-5 w-full mt-14 sm:mt-0">
                        <div className="bg-[#E5E5E5] w-full sm:w-[80px] xl:w-[100px] h-[100px] sm:h-[80px] xl:h-[100px] p-2 rounded-xl">
                          <Carousel autoplay className="w-full h-[80px] ">
                            {d?.shopStockItem?.requestedItem?.product?.product?.images?.map(
                              (im: any) => (
                                <div>
                                  <img
                                    src={im}
                                    className="w-full h-[80px]"
                                    alt=""
                                  />
                                </div>
                              )
                            )}
                          </Carousel>
                        </div>
                        <div className="mt-2 2xl:mt-0">
                          <h1 className="text-[#030229] text-base font-medium">
                            {
                              d?.shopStockItem?.requestedItem?.product?.product
                                ?.model
                            }
                          </h1>
                          <p>
                            <span className="text-[#030229B2] text-sm">
                              Type:
                            </span>{" "}
                            <span className="text-[#030229] text-sm pl-1">
                              {
                                d?.shopStockItem?.requestedItem?.product
                                  ?.product?.type
                              }
                            </span>
                          </p>
                          <p>
                            <span className="text-[#030229B2] text-sm">
                              Brand:
                            </span>{" "}
                            <span className="text-[#030229] text-sm pl-1">
                              {
                                d?.shopStockItem?.requestedItem?.product
                                  ?.product?.brand
                              }
                            </span>
                          </p>
                          <div className="flex flex-col gap-2">
                            {d?.shopStockItem?.requestedItem?.product?.specification?.map(
                              (data: any, index: number) => {
                                const regex = /\((.*?)\)/;
                                const match = data[0].match(regex);

                                if (match) {
                                  const stringFromBracket = match[1];

                                  if (
                                    !["type", "brand"].includes(
                                      stringFromBracket.toLowerCase()
                                    )
                                  ) {
                                    return (
                                      <div className="flex" key={index}>
                                        <p>
                                          <span className="text-[#030229B2] text-sm">{`${stringFromBracket}: `}</span>
                                        </p>
                                        <p className="pl-1 text-[#030229] text-sm ">
                                          {data[1]}
                                        </p>
                                      </div>
                                    );
                                  }
                                }

                                return null;
                              }
                            )}
                          </div>

                          <p>
                            <span className="text-[#030229B2] text-sm">
                              IMEI:
                            </span>{" "}
                            <span className="text-[#030229] text-sm pl-1">
                              {d?.warehouseProduct?.serialNumber}
                            </span>
                          </p>
                          <p>
                            <span className="text-[#030229B2] text-sm">
                              {priceLabel}:
                            </span>{" "}
                            <span className="text-[#030229] text-sm pl-1">
                              {d?.payment?.amount} ${getDefaultCurrencyCode(company)}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Badge.Ribbon>
              </Badge.Ribbon>
            );
          })}
          <DeleteModal
            visible={visible}
            onOk={deleteProduct}
            isLoading={wareHouse.isFetching}
            onCancel={handleDeleteModalCancels}
            itemName="Product"
          />
        </div>
      )}
    </>
  );
};

export default CartItems;
